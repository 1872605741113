import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby";
import { Helmet } from 'react-helmet';

import "./layout.css"

function openNav() {
    document.getElementById("mySidenav").style.width = "70%";
    // document.getElementById("bars").style.display = 'none';
    document.getElementById("bars").classList.add("barsHidden");
    document.getElementById("closebtn").style.display = 'block';
    document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
}

function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    // document.getElementById("bars").style.display = 'block';
    document.getElementById("bars").classList.remove("barsHidden");
    document.getElementById("closebtn").style.display = 'none';
    document.body.style.backgroundColor = "white";
}

function clickNav() {
    document.body.style.backgroundColor = "white";
}

const Layout = ({ children, pageName }) => {
  const query = useStaticQuery(graphql`
  query {
    allFile (filter: {sourceInstanceName: {eq: "content"}, relativeDirectory: {eq: "pages"} }) {
      edges {
        node {
            name
          childMarkdownRemark {
            frontmatter {
              title
            }
          }
        }
      }
    }
  }`);
  const data = query.allFile.edges.filter((edge) => edge?.node?.name === pageName)?.[0]?.node?.childMarkdownRemark?.frontmatter;

  return (
  <>
  <Helmet>
      <title>{data?.title}</title>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta http-equiv="X-UA-Compatible" content="ie=edge" />
      <link href="/favicon.ico" rel="shortcut icon" type="image/x-icon" />
      <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.0.13/css/all.css" integrity="sha384-DNOHZ68U8hZfKXOrtjWvjxusGo9WQnrNx2sqG0tfsghAvtVlRW3tvkXWZh58N9jp" crossorigin="anonymous" />
      <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.1/css/bootstrap.min.css" integrity="sha384-WskhaSGFgHYWDcbwN70/dfYBj47jz9qbsMId/iRN3ewGhXQFZCSftd1LZCfmhktB" crossorigin="anonymous" />
      <link rel="shortcut icon" type="image/x-icon" href="assets/images/default-favicon.ico"/>
      <link rel="stylesheet" href="https://unpkg.com/aos@next/dist/aos.css" />
      <link rel="stylesheet" href="assets/css/style.css" />
  </Helmet>
  <header></header>
  <main>
      <div className="container-fluid px-0 bg-dark">
          <div className="container">
              <nav className="navbar navbar-expand-lg navbar-dark bg-dark aos-init aos-animate" data-aos="fade-in" data-aos-duration="2500">
                  <Link className="navbar-brand" to="/"><img src="/assets/images/logo2.png" alt="logo" className="img-fluid logo" /></Link>

                  {/* Start Desktop Menu */}
                  <button className="navbar-toggler desktop-menu" type="button" data-toggle="collapse" data-target="#navbar1" aria-expanded="false" aria-label="Toggle navigation">
                      <i className="fa fa-bars"></i>
                  </button>
                  <div className="collapse navbar-collapse justify-content-end desktop-menu" id="navbar1">
                      <ul className="navbar-nav">
                          <li className="nav-item">
                              <Link className="nav-link" to="../">Home</Link>
                          </li>
                          <li className="nav-item">
                              <Link className="nav-link" to="../projects">Projects</Link>
                          </li>
                          {/*<li className="nav-item">*/}
                              {/*<Link className="nav-link" to="../team">Meet the Team</Link>*/}
                          {/*</li>*/}
                          {/*<li className="nav-item">*/}
                              {/*<Link className="nav-link" to="../collaborations">Collaborations</Link>*/}
                          {/*</li>*/}
                          <li className="nav-item">
                              <Link className="nav-link" to="../contact">Contact</Link>
                          </li>
                      </ul>
                  </div>
                  {/* End Desktop Menu */}

                  {/* Start Mobile Menu */}
                  <span role = "button" tabIndex='0' className="mobo-menu mobo-bars" id="bars" onClick={openNav} onKeyDown={openNav}>&#9776;</span>
                  <span role = "button" tabIndex='0' id="closebtn"><Link to="#" className="closebtn" onClick={closeNav} onKeyDown={closeNav}>&times;</Link></span>
                  <div id="mySidenav" className="sidenav mobo-menu">
                      <Link onClick={clickNav} onKeyDown={clickNav} to="../">Home</Link>
                      <Link onClick={clickNav} onKeyDown={clickNav} to="../projects">Projects</Link>
                      {/*<Link onClick={clickNav} onKeyDown={clickNav} to="../team">Meet the Team</Link>*/}
                      {/*<Link onClick={clickNav} onKeyDown={clickNav} to="../collaborations">Collaborations</Link>*/}
                      <Link onClick={clickNav} onKeyDown={clickNav} to="../contact">Contact</Link>
                  </div>
                  {/* End Mobile Menu */}
              </nav>
          </div>
      </div>

      <div className="container-fluid" id="main">
          {children}
      </div>


      <div className="container-fluid footer">
          <div className="row">
              <div className="col-12">
                  <h3 className="text-center text-white"><span className="size1.2">©</span> {(new Date().getFullYear().toString() === '2021')?"":"2021-"}{new Date().getFullYear()} Designed and developed by <a target="_blank" rel="noreferrer" href="https://redhotcoding.com">RedHot coding</a></h3>
              </div>
          </div>
      </div>

  </main>
  </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
